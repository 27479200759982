import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box
} from '@mui/material';

function InterestCalculator() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [principal, setPrincipal] = useState('');
    const [yearType, setYearType] = useState('auto');
    const [payments, setPayments] = useState([{ date: '', amount: '' }]);
    const [result, setResult] = useState(null);
    const [interestRates, setInterestRates] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [error, setError] = useState('');


    useEffect(() => {
        axios.get('https://api.hukukislemci.com/interest-rates')
            .then(response => {
                setInterestRates(response.data);
            })
            .catch(error => {
                console.error('Error fetching interest rates:', error);
            });
    }, []);

    const handlePaymentChange = (index, field, value) => {
        const newPayments = [...payments];
        newPayments[index][field] = value;
        setPayments(newPayments);
    };

    const handleAddPayment = () => {
        setPayments([...payments, { date: '', amount: '' }]);
    };

    const handleCalculateInterest = async (e) => {
      e.preventDefault();
      try {
          const validPayments = payments
              .filter(payment => payment.amount && payment.date) // amount ve date boş değilse
              .map(payment => ({
                  date: payment.date,
                  amount: parseFloat(payment.amount),
              }));
  
          const response = await axios.post('https://api.hukukislemci.com/calculate-interest', 
          {
              start_date: startDate,
              end_date: endDate,
              principal: parseFloat(principal),
              payments: validPayments,
              year_type: yearType,
          }, 
          {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          });
          setResult(response.data);
      } catch (err) {
        if(err.response.status == 401)
        {
          window.location.reload(); 
        }
          console.error(err.response.status)
          console.error(err);
          setError('Calculation failed');
      }
  };

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://api.hukukislemci.com/token', 
                new URLSearchParams({
                    'username': username,
                    'password': password
                }), 
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );
            const token = response.data.access_token;
            localStorage.setItem('token', token);
            setToken(token);
        } catch (err) {
            console.error(err);
            setError('Login failed');
        }
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h4" align="center" gutterBottom>
                Interest Calculator
            </Typography>
            {!token ? (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                        Login
                    </Typography>
                    <TextField
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                </Box>
            ) : (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Calculate Interest
                    </Typography>
                    <form onSubmit={handleCalculateInterest}>
                        <TextField
                            label="Start Date"
                            type="date"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="End Date"
                            type="date"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Principal"
                            type="number"
                            value={principal}
                            onChange={e => setPrincipal(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Year Type</InputLabel>
                            <Select
                                value={yearType}
                                onChange={e => setYearType(e.target.value)}
                            >
                                <MenuItem value="auto">Auto</MenuItem>
                                <MenuItem value="365">365 Days</MenuItem>
                                <MenuItem value="360">360 Days</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography variant="h6" gutterBottom>
                            Payments
                        </Typography>
                        {payments.map((payment, index) => (
                            <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                <TextField
                                    label="Payment Date"
                                    type="date"
                                    value={payment.date}
                                    onChange={e => handlePaymentChange(index, 'date', e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                                <TextField
                                    label="Payment Amount"
                                    type="number"
                                    value={payment.amount}
                                    onChange={e => handlePaymentChange(index, 'amount', e.target.value)}
                                    fullWidth
                                />
                            </Box>
                        ))}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddPayment}
                            sx={{ mr: 2 }}
                        >
                            Add Payment
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                        >
                            Calculate Interest
                        </Button>
                        {error && (
                            <Typography color="error" variant="body2">
                                {error}
                            </Typography>
                        )}
                    </form>
                    {result && (
                        <Box mt={4}>
                            <Typography variant="h6">
                                Result
                            </Typography>
                            <Typography>Total Interest: {result.total_interest.toFixed(2)}</Typography>
                            <Typography>Remaining Principal: {result.remaining_principal.toFixed(2)}</Typography>
                        </Box>
                    )}
                </Box>
            )}
            <Box mt={4}>
                <Typography variant="h6">
                    Interest Rates
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Rate (%)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {interestRates.map((rate, index) => (
                                <TableRow key={index}>
                                    <TableCell>{new Date(rate.start_date).toLocaleDateString()}</TableCell>
                                    <TableCell>{new Date(rate.end_date).toLocaleDateString()}</TableCell>
                                    <TableCell>{rate.rate}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
}

export default InterestCalculator;
